<template>
  <div>

    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$t('Companies')"
        :items="companies2"
        :items-context="companiesContext"
        :available-filters="$store.getters['company2/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"
        :allow-view-windows="true"
        :is-view-windows-ready="displayedCompany!=null"

        @tryToUpdateKanbanItem="tryToUpdateKanbanItem"
        @addItem="addCompany"
        @showDetails="showDetails"
    >
      <!--      <template #kanbanItemView="{item}">-->
      <!--        <company-card-->
      <!--            :company="item"-->
      <!--            :is-viewable="true"-->
      <!--            :is-selected="item.id==currentCompany.id"-->
      <!--            :is-edit-allowed="false"-->
      <!--            :is-trash-allowed="false"-->

      <!--            @click="showDetails(item)"-->
      <!--            @view="showDetails(item)"-->
      <!--        />-->
      <!--      </template>-->

      <template #listView_cell_actions="{item}">
        <!--          Edit -->
        <button-edit
            @click.native.stop="editCompanyLocal(item)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            @click.native.stop="deleteCompanyAlert(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

      </template>

      <template #leftViewWindow="{item}">
        <company-card
            :company="item"
            :is-viewable="true"
            :is-selected="item.id==displayedCompany.id"
            :is-edit-allowed="false"
            :is-trash-allowed="false"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #viewWindowTitle>
        {{ displayedCompany.name }}
      </template>
      <template #viewWindowButtons>
        <!--          Edit -->
        <button-edit
            @click.native.stop="editCompanyLocal(displayedCompany)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>
      <template #windowView>
        <company-view :company="displayedCompany"/>
      </template>
    </base-template>

    <!-- modal workFlo -->
    <modal-company
        :company.sync="currentCompany"
        :title="('id' in currentCompany)?$t('EditCompany'):$t('NewCompany')"
        :isOpen.sync="companyModalShow"
        @submitValidated="submitValidatedCompanyLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useCompanies }         from '../../views/contacts2/contactCompanies/useCompanies'
import { capitalize }           from '../../utils/filter'
import ButtonEdit               from '../button/Edit.vue'
import BaseTemplate             from '../base3/Base.vue'
import ButtonRemove             from '../button/Remove.vue'
import WorkFloCard              from '../card/WorkFlo.vue'
import CompanyCard              from '../card/CompanyCard.vue'
import ModalCompany             from '../prompt/Company.vue'
import useAPI                   from '../../utils/useAPI'
import store                    from '../../store'
import SupplierInvoiceView      from './SupplierInvoiceView.vue'
import SupplierInvoiceCard      from '../card/IncomingInvoice2.vue'
import CompanyView              from './CompanyView.vue'

export default {
  components: {
    CompanyView,
    SupplierInvoiceCard,
    SupplierInvoiceView, ModalCompany, CompanyCard, WorkFloCard, ButtonRemove, BaseTemplate, ButtonEdit
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const baseTemplate = ref()
    const module = ref('contactCompanies')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const displayedCompany = ref(null)
    const currentCompany = ref({})
    const companyModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { companies2, companiesContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      fetchCompanies2(val)
          .then(() => {
            if (!isAutocompleteLoaded.value) {
              loadInit()
            }
          })
    }, { deep: true })

    watch(companyModalShow, val => {
      if (val == false) {
        // resetCompany()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedCompany, removeCompany } = useCompanies()
    const { fetchCompanies2 } = useAPI()

    const loadInit = () => {
      resetCompany()

      isAutocompleteLoaded.value = true
    }

    const addCompany = () => {
      resetCompany()
      companyModalShow.value = true
    }

    const tryToUpdateKanbanItem = (tryToUpdateKanbanItem) => {
      store.dispatch('company2/getCompany', tryToUpdateKanbanItem.not_updated_object.id)
          .then((response) => {
            currentCompany.value = response

            submitValidatedCompanyLocal()
          })
    }

    const resetCompany = () => {
      currentCompany.value = JSON.parse(JSON.stringify(store.getters['company2/getEmptyCompany']))
    }

    const editCompanyLocal = (company) => {
      store.dispatch('company2/getCompany', company.id)
          .then(response => {
            currentCompany.value = response.data
            companyModalShow.value = true
          })
    }

    const removeCompanyLocal = (company) => {
      removeCompany(company)
          .then(response => {

          })
    }

    const submitValidatedCompanyLocal = () => {
      submitValidatedCompany(currentCompany.value)
          .then(response => {
            companyModalShow.value = false
          })
    }

    const showDetails = (company) => {
      if (company != null) {
        store.dispatch('company2/getCompany', company.id)
            .then(response => {
              // console.log(response)
              displayedCompany.value = response.data
            })

      } else {
        resetCompany()
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetCompany()
    store.dispatch('moduleView/getModuleViews', {
      per_page: 5000
    })
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)
// console.log(currentView)
          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            page: 1,
            sorts: currentView.sorts,
          }

        })

    return {
      // Components
      capitalize,

      // Data
      baseTemplate,
      module,
      payload,
      isReady,
      displayedCompany,
      currentCompany,
      companyModalShow,

      // Computed
      companies2,
      companiesContext,

      // Methods
      tryToUpdateKanbanItem,
      editCompanyLocal,
      removeCompanyLocal,
      submitValidatedCompanyLocal,
      resetCompany,
      addCompany,
      showDetails,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteCompanyAlert (company) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theCompany') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeCompanyLocal(company)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>