<template>
  <div>

    <b-alert
        variant="info"
        class="mb-0"
        show
    >
      <div class="alert-body mb-1">
        <icon
            icon="info-circle"
            class="mr-50"
        />
        <span class="my-auto">{{ capitalize($t('since', { 'since': 'le 1 juillet 2022' })) }}</span>
      </div>
    </b-alert>

    <div class="d-flex justify-content-between mb-2">
      <h1>{{ capitalize($t('balance:')) }}</h1>
      <h1
          v-if="company._ledger"
          :class="company._ledger.balance==0?'text-primary':'text-danger'"
      >
        <span v-if="company._ledger.balance>0">+</span>
        {{ currency(company._ledger.balance) }}
      </h1>
    </div>

    <b-tabs>
      <b-tab :title="$t('Charges') + ' ('+charges.items.length+')'">
        <b-table
            striped
            borderless
            hover
            foot-clone
            :fields="fields"
            :items="charges.items"
        >

          <template #cell(date)="data">
            <div class="text-nowrap">{{ data.item.date|moment('LL') }}</div>
          </template>

          <template #cell(type)="data">
            <div class="text-nowrap">{{ capitalize($tc(data.item.type)) }}</div>
          </template>

          <template #cell(debit)="data">
            <div
                v-if="data.item.debit != 0"
                class="text-nowrap"
            >
              {{ currency(data.item.debit) }}
            </div>
            <div v-else></div>
          </template>

          <template #cell(credit)="data">
            <div
                v-if="data.item.credit != 0"
                class="text-nowrap"
            >
              {{ currency(data.item.credit) }}
            </div>
            <div v-else></div>
          </template>

          <template #foot()="data">
            <div
                v-if="data.column == 'debit' && company._ledger"
                class="text-nowrap"
            >
              {{ currency(charges.totalDebit) }}
            </div>
            <div
                v-else-if="data.column == 'credit' && company._ledger"
                class="text-nowrap"
            >
              {{ currency(charges.totalCredit) }}
            </div>
            <div v-else>

            </div>
          </template>
        </b-table>
      </b-tab>

      <b-tab :title="capitalize($tc('product',2)) + ' ('+products.items.length+')'">
        <b-table
            striped
            borderless
            hover
            foot-clone
            :fields="fields"
            :items="products.items"
        >

          <template #cell(date)="data">
            <div class="text-nowrap">{{ data.item.date|moment('LL') }}</div>
          </template>

          <template #cell(type)="data">
            <div class="text-nowrap">{{ capitalize($tc(data.item.type)) }}</div>
          </template>

          <template #cell(debit)="data">
            <div
                v-if="data.item.debit != 0"
                class="text-nowrap"
            >
              {{ currency(data.item.debit) }}
            </div>
            <div v-else></div>
          </template>

          <template #cell(credit)="data">
            <div
                v-if="data.item.credit != 0"
                class="text-nowrap"
            >
              {{ currency(data.item.credit) }}
            </div>
            <div v-else></div>
          </template>

          <template #foot()="data">
            <div
                v-if="data.column == 'debit' && company._ledger"
                class="text-nowrap"
            >
              {{ currency(products.totalDebit) }}
            </div>
            <div
                v-else-if="data.column == 'credit' && company._ledger"
                class="text-nowrap"
            >
              {{ currency(products.totalCredit) }}
            </div>
            <div v-else>

            </div>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../utils/filter'
import { currency }             from '../../utils/filter'

import i18n from '../../libs/i18n'

export default {
  components: {},
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const fields = computed(() => {
      return [
        {
          key: 'piece',
          label: capitalize(i18n.tc('piece'))
        },
        {
          key: 'date',
          label: capitalize(i18n.tc('date'))
        },
        {
          key: 'type',
          label: capitalize(i18n.tc('type'))
        },
        {
          key: 'wording',
          label: capitalize(i18n.tc('wording'))
        },
        {
          key: 'debit',
          label: capitalize(i18n.tc('debit'))
        },
        {
          key: 'credit',
          label: capitalize(i18n.tc('credit'))
        },
      ]
    })

    const ledger = computed(() => {
      return props.company._ledger ? props.company._ledger.ledger : []
    })

    const charges = computed(() => {
      return props.company._ledger ? props.company._ledger.ledger.charges : {
        totalCredit: 0,
        totalDebit: 0,
        items: []
      }
    })

    const products = computed(() => {
      return props.company._ledger ? props.company._ledger.ledger.products : {
        totalCredit: 0,
        totalDebit: 0,
        items: []
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data

      // Computed
      fields,
      ledger,
      charges,
      products,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.alert .alert-body svg {
  top: 0px;
}
</style>