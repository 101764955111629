import { render, staticRenderFns } from "./ContactCompaniesView2.vue?vue&type=template&id=3149ecad&scoped=true&"
import script from "./ContactCompaniesView2.vue?vue&type=script&lang=js&"
export * from "./ContactCompaniesView2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3149ecad",
  null
  
)

export default component.exports