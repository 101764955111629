<template>

  <generic-card
      :byContent="true"

      @click="$emit('click')"
      @delete="$emit('deleteWorkFlo')"

  >
    <template #content>
      <content-workFlo
          :workFlo="workFlo"
          :isParentMethodPriority="true"
          :isPayable="isPayable"
          :isDownloadable="isDownloadable"
          :isViewable="isViewable"
          :is-editable="true"
          :isDeletable="true"
          :is-selected="isSelected"
          @view="$emit('view')"
          @edit="$emit('edit')"
          @delete="$emit('delete')"
          @pay="$emit('pay')"
      />
    </template>

  </generic-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import GenericCard from './Card'
import ContentWorkFlo from './components/WorkFloContent'

export default {
  components: {
    GenericCard,
    ContentWorkFlo
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    },
    isDownloadable: {
      type: Boolean,
      default: false
    },
    isViewable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isParentMethodPriority: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>