<template>
  <div>

    <b-tabs>
      <b-tab :title="capitalize($tc('invoice', 1))">
        <iframe
                v-if="'receipt' in supplierInvoice"
            :src="supplierInvoice.receipt.url"
            width="100%"
            height="1192px"
        />
      </b-tab>

      <b-tab :title="capitalize($tc('payment', 2)) + ' ('+supplierInvoice.paymentRelations.length+')'">
        <!--        <b-row>-->
        <!--          <b-col-->
        <!--              cols="12"-->
        <!--              md="6"-->
        <!--              class="mt-md-0 mt-3"-->
        <!--              order="2"-->
        <!--              order-md="1"-->
        <!--          >-->

        <h6
            class="mb-50"
        >
          <b>{{ capitalize($tc('payment', 2)) }} :</b>
        </h6>
        <div v-if="supplierInvoice.paymentRelations.length == 0">
          <i>{{ $t('NoPayment') }}</i>
        </div>
        <b-table
            v-if="supplierInvoice.paymentRelations.length"
            small
            :fields="paymentsTable.fields"
            :items="paymentsTable.items"
        />

        <!--          </b-col>-->
        <!--        </b-row>-->
      </b-tab>


    </b-tabs>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'

import i18n from '../../libs/i18n'
import moment from 'moment'

export default {
  components: {},
  props: {
    supplierInvoice: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const paymentsMade = computed(() => {
      let paymentsMade = 0
      props.supplierInvoice.paymentRelations.forEach(pr => paymentsMade += parseFloat(pr.amount))

      return paymentsMade
    })

    const paymentsTable = computed(() => {
      let payments = []
      props.supplierInvoice.paymentRelations.forEach(pr => {
        let tmp = {}
        tmp[i18n.t('date')] = moment(pr.paiement.date).format('L')
        tmp[i18n.t('bankAccount')] = i18n.t(pr.paiement.bankAccount._display)
        tmp[i18n.t('paymentMethod')] = i18n.t(pr.paiement.paymentMethod.name)
        tmp[i18n.t('amount')] = currency(pr.amount)
        payments.push(tmp)
      })

      return {
        fields: [i18n.t('date'), i18n.t('bankAccount'), i18n.t('paymentMethod'), i18n.t('amount')],
        items: payments
      }
    })

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed

      // Methods
      paymentsMade,
      paymentsTable,

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>