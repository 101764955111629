<template>
  <generic-content
      :isLinkable="false"
      :isEditable="isEditable"
      :isPayable="isPayable"
      :isDeletable="isDeletable"
      :isDownloadable="isDownloadable"
      :isViewable="isViewable"
      :is-selected="isSelected"
      @click="$emit('click')"
      @view="$emit('view')"
      @edit="$emit('edit')"
      @download="$emit('download')"
      @delete="$emit('delete')"
  >
    <template #bodyContent>
      <div class="d-flex justify-content-between">
        <div
            v-if="workFlo._warnings.length"
            :title="'<ul class=\'text-left\'><li>'+workFlo._warnings.join('</li><li>')+'</li></ul>'"
            v-b-tooltip.html
            class="text-nowrap mr-50 my-auto"
        >
          <icon icon="exclamation-triangle"/>
        </div>

        <h6 class="text-truncate my-auto w-100">
          <b>{{ workFlo.title }}</b>
        </h6>

        <!--        <icon-->
        <!--            :icon="status.icon"-->
        <!--            :class="'text-'+status.variant"-->
        <!--            v-b-tooltip.hover.top="status.text"-->
        <!--        />-->

        <!--                <vue-apex-charts-->
        <!--                    type="radialBar"-->
        <!--                    height="30"-->
        <!--                    width="30"-->
        <!--                    :options="progressChartOptions"-->
        <!--                    :series="progressChartSeries"-->
        <!--                    v-b-tooltip.hover.top="status.text"-->
        <!--                />-->

      </div>

      <div
          v-if="'_lastActivity' in workFlo"
          v-b-tooltip.hover
          :title="displayLastActivity.text"
      >
        <b-badge :variant="displayLastActivity.color" class="w-100">
          <icon
              :icon="displayLastActivity.icon.icon"
              class="mr-50"
          />
          {{ displayLastActivity.text }}
        </b-badge>
      </div>


      <div class="text-truncate">{{ workFlo.company._display }}</div>

      <!--      WorkFlo tasks-->
      <div
          v-if="workFlo._history.taskList.length"
          :id="'workFloTasksPopover-'+workFlo.id"
          class="my-50"
          @mouseover="workFloTasksPopover = true"
          @mouseleave="workFloTasksPopover = false"
      >
        <div class="d-flex">
          <div style="width: 30px">
            <icon
                icon="check"
            />
          </div>
          <div>
            {{ workFlo._history.taskList.length }}
            {{
              $tc('task', workFlo._history.taskList.length)
            }}
          </div>
        </div>
      </div>
      <b-popover
          :show.sync="workFloTasksPopover"
          :target="'workFloTasksPopover-'+workFlo.id"
      >
        <template #title>
          {{ workFlo._history.taskList.length }}
          {{
            $tc('task', workFlo._history.taskList.length)
          }}
        </template>

        <div
            v-for="(task, index) in workFlo._history.taskList"
            :key="'workFloTasksPopover-'+workFlo.id+'_'+index"
            class="border-left-primary border-left-3 d-flex"
            :class="{'mt-1': index>=1}"
        >
          <div class="my-auto ml-50">
            <icon :icon="getTaskIcon(task)"/>
          </div>

          <div class="ml-50">
            <b class="text-primary">{{ task.title }}</b>
            <br/>
            <span
                :class="{'text-danger': isLate(task)}"
            >
              <i>{{ task.date|moment('LL') }}</i>
            </span>
          </div>
        </div>
      </b-popover>

      <!--      WorkFlo calls-->
      <div
          v-if="workFlo._history.comingSoon.workFloCalls.length"
          :id="'workFloCallsPopover-'+workFlo.id"
          class="my-50"
          @mouseover="workFloCallsPopover = true"
          @mouseleave="workFloCallsPopover = false"
      >
        <div class="d-flex">
          <div style="width: 30px">
            <icon
                icon="phone"
            />
          </div>
          <div>
            {{ workFlo._history.comingSoon.workFloCalls.length }}
            {{
              $tc('call', workFlo._history.comingSoon.workFloCalls.length)
            }}
          </div>
        </div>
      </div>
      <b-popover
          :show.sync="workFloCallsPopover"
          :target="'workFloCallsPopover-'+workFlo.id"
      >
        <template #title>
          {{ workFlo._history.comingSoon.workFloCalls.length }}
          {{
            $tc('call', workFlo._history.comingSoon.workFloCalls.length)
          }}
        </template>

        <div
            v-for="(call, index) in workFlo._history.comingSoon.workFloCalls"
            :key="'workFloCallsPopover-'+workFlo.id+'_'+index"
            class="border-left-primary border-left-3"
            :class="{'mt-1': index>=1}"
        >
          <div class="ml-50">
            <b class="text-primary">{{ call.contact ? call.contact._display : '-' }}</b>
            <br/>
            <span>
              <i>{{ call.date|moment('LL') }}</i>
            </span>
          </div>
        </div>
      </b-popover>

      <!--      WorkFlo events-->
      <div
          v-if="workFlo._history.comingSoon.events.length"
          :id="'workFloEventsPopover-'+workFlo.id"
          class="my-50"
          @mouseover="workFloEventsPopover = true"
          @mouseleave="workFloEventsPopover = false"
      >
        <div class="d-flex">
          <div style="width: 30px">
            <icon
                icon="calendar-alt"
            />
          </div>
          <div>
            {{ workFlo._history.comingSoon.events.length }}
            {{
              $tc('event', workFlo._history.comingSoon.events.length)
            }}
          </div>
        </div>
      </div>
      <b-popover
          :show.sync="workFloEventsPopover"
          :target="'workFloEventsPopover-'+workFlo.id"
      >
        <template #title>
          {{ workFlo._history.comingSoon.events.length }}
          {{
            $tc('event', workFlo._history.comingSoon.events.length)
          }}
        </template>

        <div
            v-for="(event, index) in workFlo._history.comingSoon.events"
            :key="'workFloEventsPopover-'+workFlo.id+'_'+index"
            class="border-left-primary border-left-3"
            :class="{'mt-1': index>=1}"
        >
          <div class="ml-50">
            <b class="text-primary">{{ event.title }}</b>
            <br/>
            <span>
              <i>{{ event.startDate|moment('LLL') }}</i>
            </span>
          </div>
        </div>
      </b-popover>

      <!--      WorkFlo interventions-->
      <div
          v-if="workFlo._history.comingSoon.interventions.length"
          :id="'workFloInterventionsPopover-'+workFlo.id"
          class="my-50"
          @mouseover="workFloInterventionsPopover = true"
          @mouseleave="workFloInterventionsPopover = false"
      >
        <div class="d-flex">
          <div style="width: 30px">
            <icon
                icon="road"
            />
          </div>
          <div>
            {{ workFlo._history.comingSoon.interventions.length }}
            {{
              $tc('intervention', workFlo._history.comingSoon.interventions.length)
            }}
          </div>
        </div>
      </div>
      <b-popover
          :show.sync="workFloInterventionsPopover"
          :target="'workFloInterventionsPopover-'+workFlo.id"
      >
        <template #title>
          {{ workFlo._history.comingSoon.interventions.length }}
          {{
            $tc('intervention', workFlo._history.comingSoon.interventions.length)
          }}
        </template>

        <div
            v-for="(intervention, index) in workFlo._history.comingSoon.interventions"
            :key="'workFloInterventionsPopover-'+workFlo.id+'_'+index"
            class="border-left-primary border-left-3"
            :class="{'mt-1': index>=1}"
        >
          <div class="ml-50">
            <b class="text-primary">{{ intervention.title }}</b>
            <br/>
            <span>
              <i>{{ intervention.startDate|moment('LLL') }}</i>
            </span>
          </div>
        </div>
      </b-popover>

    </template>

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../utils/filter'

import GenericContent from './Content'
import i18n           from '@/libs/i18n'
import Ripple         from 'vue-ripple-directive'
import moment         from 'moment'
import VueApexCharts  from 'vue-apexcharts'
import store          from '../../../store'
import { clone }      from '../../../utils/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    GenericContent,
    VueApexCharts,
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    },
    isDownloadable: {
      type: Boolean,
      default: false
    },
    isViewable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isParentMethodPriority: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const workFloTasksPopover = ref(false)
    const workFloCallsPopover = ref(false)
    const workFloEventsPopover = ref(false)
    const workFloInterventionsPopover = ref(false)
    const progressChartOptions = ref({
      grid: {
        show: false,
        padding: {
          left: -15,
          right: -15,
          top: -12,
          bottom: -15,
        },
      },
      colors: ['rgba(40,199,111,0.85)', 'rgba(234,84,85,0.85)'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '22%',
          },
          track: {
            background: '#e9ecef',
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      fill: {
        colors: [function ({ value, seriesIndex, w }) {
          if (value < 25) {
            return 'rgba(40,199,111,0.85)'
          } else if (value < 50) {
            return 'rgba(255,159,67,0.85)'
          } else {
            return 'rgba(234,84,85,0.85)'
          }
        }]
      },
      stroke: {
        lineCap: 'round',
      },
    })

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconWorkFloType = computed(() => {
      return 'question'
    })

    const status = computed(() => {
      if (props.workFlo._history.status == 'no action') {
        let moreRecent = props.workFlo._history.lastAction
        // let moreRecent = moment(props.workFlo._history.history[0].date) > moment(props.workFlo._history.lastAction)?props.workFlo._history.history[0].date:props.workFlo._history.lastAction
        // let moreRecent = (props.workFlo._history.nextAction != null && moment(props.workFlo._history.nextAction)>moment())?moment(props.workFlo._history.nextAction):(moment(props.workFlo._history.history[0].date) > moment(props.workFlo._history.lastAction)?props.workFlo._history.history[0].date:props.workFlo._history.lastAction)

        if (moreRecent == null) {
          moreRecent = props.workFlo._history.history[props.workFlo._history.history.length - 1].date
        }

        return {
          icon: 'info-circle',
          variant: 'warning',
          // text: capitalize(i18n.t('noActionFor')) + ' ' + moment(props.workFlo._history.history[0].date).toNow(true)
          text: capitalize(i18n.t('noActionFor')) + ' ' + moment(moreRecent).toNow(true)
        }
      } else if (props.workFlo._history.status == 'late') {
        if (props.workFlo._history.nextAction) {
          return {
            icon: 'bell',
            variant: 'danger',
            text: capitalize(i18n.t('lateFor')) + ' ' + moment(props.workFlo._history.nextAction).toNow(true)
            // text: capitalize(i18n.t('lateFor')) + ' ' + moment(props.workFlo._history.lastAction).toNow(true)
          }
        } else {

          return {
            icon: 'bell',
            variant: 'danger',
            // text: capitalize(i18n.t('lateFor')) + ' ' + moment(props.workFlo._history.taskList[0].date).toNow(true)
            text: capitalize(i18n.t('lateFor')) + ' ' + moment(props.workFlo._history.lastAction).toNow(true)
          }
        }
      } else {
        return {
          icon: 'history',
          variant: 'secondary',
          text: capitalize(i18n.t('nextActionIn')) + ' ' + moment(props.workFlo._history.nextAction).fromNow(true)
        }
      }
    })

    const progressChartSeries = computed(() => {
      let lastAction = props.workFlo._history.lastAction
      let nextAction = props.workFlo._history.nextAction

      if (nextAction != null && moment(nextAction) >= moment()) {
        return [0]
      } else {
        if (moment(lastAction) >= moment()) {
          return [0]
        } else {
          let diffDays = moment().diff(moment(lastAction), 'days')

          let days = props.workFlo.status.delayDay

          if (lastAction == null || diffDays >= days) {
            // console.log(props.workFlo.title, 100)
            return [100]
          } else if (diffDays <= 0) {
            // console.log(props.workFlo.title, 0)
            return [0]
          } else {
            // console.log(props.workFlo.title, parseInt((diffDays*100)/days))
            return [parseInt((diffDays * 100) / days)]
          }
        }
      }

    })

    const displayNextActivity = computed(() => {
      if (
          '_nextActivity' in props.workFlo &&
          props.workFlo._nextActivity.type != null
      ) {

        // if (props.workFlo._nextActivity.entity.type != null) {
        let type = capitalize(i18n.tc('event'))

        if (
            props.workFlo._nextActivity.entity.type == 'call' ||
            props.workFlo._nextActivity.entity.type == 'discussion' ||
            props.workFlo._nextActivity.entity.type == 'mail'
        ) {
          type = capitalize(i18n.t('exchange'))
        } else if (props.workFlo._nextActivity.entity.type == 'intervention') {
          type = capitalize(i18n.tc('intervention'))
        } else if (props.workFlo._nextActivity.entity.type == 'trip') {
          type = capitalize(i18n.tc('trip'))
        }

        if (props.workFlo._nextActivity.type == 'event') {

          return {
            icon: getIcon(props.workFlo._nextActivity.entity.type),
            color: 'secondary',
            text: i18n.t('Xscheduled', { type: type }) + ' ' + displayDate(props.workFlo._nextActivity.entity.startDate)
          }
        } else if (props.workFlo._nextActivity.type == 'reminder') {
          if (type == capitalize(i18n.tc('event'))) {
            type = capitalize(i18n.tc('reminder'))
          }

          return {
            icon: getIcon(props.workFlo._nextActivity.entity.type),
            color: 'secondary',
            text: i18n.t('Xscheduled', { type: type }) + ' ' + displayDate(props.workFlo._nextActivity.entity.completionDate)
          }
        }
      } else {
        return {
          icon: {
            icon: 'exclamation-triangle',
            library: ''
          },
          color: 'danger',
          text: capitalize(i18n.t('noActionPlanned'))
        }
      }
      // } else {
      //   return {
      //     icon: {
      //       icon: 'exclamation-triangle',
      //       library: ''
      //     },
      //     color: 'danger',
      //     text: capitalize(i18n.t('noActionPlanned'))
      //   }
      // }
    })

    const displayLastActivity = computed(() => {
      if (
          '_lastActivity' in props.workFlo &&
          props.workFlo._lastActivity.type != null
      ) {
        let color = 'secondary'
        let now = moment()

        // if (props.workFlo._lastActivity.entity.type != null) {
        let type = capitalize(i18n.tc('event'))

        if (
            props.workFlo._lastActivity.entity.type == 'call' ||
            props.workFlo._lastActivity.entity.type == 'discussion' ||
            props.workFlo._lastActivity.entity.type == 'mail'
        ) {
          type = capitalize(i18n.t('exchange'))
        } else if (props.workFlo._lastActivity.entity.type == 'intervention') {
          type = capitalize(i18n.tc('intervention'))
        } else if (props.workFlo._lastActivity.entity.type == 'trip') {
          type = capitalize(i18n.tc('trip'))
        }

        if (props.workFlo._lastActivity.type == 'event') {
          let date = moment(props.workFlo._lastActivity.entity.startDate)

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
          }

          return {
            icon: getIcon(props.workFlo._lastActivity.entity.type),
            color: color,
            text: i18n.t('Xscheduled', { type: type }) + ' ' + displayDate(props.workFlo._lastActivity.entity.startDate)
          }
        } else if (props.workFlo._lastActivity.type == 'reminder') {
          let date = moment(props.workFlo._lastActivity.entity.completionDate)

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
          }

          if (type == capitalize(i18n.tc('event'))) {
            type = capitalize(i18n.tc('reminder'))
          }

          return {
            icon: getIcon(props.workFlo._lastActivity.entity.type),
            color: color,
            text: i18n.t('Xscheduled', { type: type }) + ' ' + displayDate(props.workFlo._lastActivity.entity.completionDate)
          }
        }
      } else {
        return {
          icon: {
            icon: 'exclamation-triangle',
            library: ''
          },
          color: 'danger',
          text: capitalize(i18n.t('noActionPlanned'))
        }
      }
      // } else {
      //   return {
      //     icon: {
      //       icon: 'exclamation-triangle',
      //       library: ''
      //     },
      //     color: 'danger',
      //     text: capitalize(i18n.t('noActionPlanned'))
      //   }
      // }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const isLate = (task) => {
      if (moment(task.date) < moment()) {
        return true
      } else {
        return false
      }
    }

    const getTaskIcon = (task) => {
      if (task.type == 'call') {
        return 'phone'
      } else if (task.type == 'appointment') {
        return 'calendar-alt'
      } else if (task.type == 'email') {
        return 'envelope'
      } else if (task.type == 'milestone') {
        return 'map-marked-alt'
      } else {
        return 'check'
      }
    }

    const displayDate = (date) => {
      let now = moment()
      let tomorrow = moment(clone(now)).add(1, 'days')

      if (now.format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
        return i18n.t('today')
      } else if (tomorrow.format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
        return i18n.t('tomorrow')
      } else {
        return i18n.t('the') + '  ' + (moment(date).format('LL'))
      }
    }

    const getIcon = type => {
      if (type == 'work') {
        return {
          icon: 'user-circle',
          library: 'fas'
        }
      } else if (type == 'discussion') {
        return {
          icon: 'comments',
          library: 'fas'
        }
      } else if (type == 'call') {
        return {
          icon: 'phone',
          library: 'fas'
        }
      } else if (type == 'mail') {
        return {
          icon: 'envelope',
          library: 'fas'
        }
      } else if (type == 'intervention') {
        return {
          icon: 'briefcase',
          library: 'fas'
        }
      } else if (type == 'trip') {
        return {
          icon: 'suitcase-rolling',
          library: 'fas'
        }
      } else {
        return {
          icon: 'exclamation-triangle',
          library: 'fas'
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,
      capitalize,

      // Data
      workFloTasksPopover,
      workFloCallsPopover,
      workFloEventsPopover,
      workFloInterventionsPopover,
      progressChartOptions,
      progressChartSeries,

      // Computed
      iconWorkFloType,
      status,
      displayNextActivity,
      displayLastActivity,
      // statusColor,

      // Methods
      isLate,
      getTaskIcon,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {

  },
  created () {
  }
}
</script>

<style lang="scss">

</style>