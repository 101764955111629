import store from '@/store'

export const useCompanies = () => {
  // ======================================================================
  // ==================              METHODS             ==================
  // ======================================================================
  const submitValidatedCompany = (company) => {
    return new Promise((resolve, reject) => {
      if ('id' in company) {
        updateCompany(company)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewCompany(company)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewCompany = (company) => {
    return new Promise((resolve, reject) => {
      store.dispatch('company2/addCompany', company)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateCompany = (company) => {
    return new Promise((resolve, reject) => {
      store.dispatch('company2/updateCompany', company)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeCompany = (company) => {
    return new Promise((resolve, reject) => {
      store.dispatch('company2/removeCompany', company)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  // ======================================================================
  // ==================               SETUP              ==================
  // ======================================================================
  return {
    submitValidatedCompany,
    removeCompany
  }
}